body {
	padding: 0;
	margin: 0; }

.container {
	max-width: 1170px;
	padding: 0; }

input, button, input:active, button:active {
	outline: none; }
:focus {
	outline: none; }

ul {
	list-style-type: none;
	padding: 0;
	margin: 0; }

.clear {
	clear: both; }

a:focus {
	outline: none; }

h2, h3 {
	margin-top: 0;
	margin-bottom: 0; }

p {
	margin-top: 0;
	margin-bottom: 0; }

.bx-wrapper {
	margin-bottom: 0; }
.bx-wrapper .bx-viewport {
	left: 0;
	border: 0;
	box-shadow: none; }
.hidden-contant {
	display: none; }

header {
	padding-bottom: 8px;
	.logo-wrap {
		.logo {
			display: block;
			margin-top: 19px; }
		p {
			color: #67087b;
			font-family: "Open Sans";
			font-size: 11px;
			font-weight: 600;
			line-height: 13px;
			text-transform: uppercase;
			text-align: center;
			max-width: 181px;
			margin-left: 14px;
			padding-top: 6px; } }
	.top-menu {
		padding-top: 37px;
		ul {
			text-align: center;
			li {
				display: inline-block;
				margin-right: 26px;
				&:last-child {
					margin-right: 0; }
				a {
					color: #202d33;
					font-family: "Open Sans";
					font-size: 12px;
					font-weight: 600;
					text-decoration: underline;
					-webkit-transition: all .25s;
					-moz-transition: all .25s;
					-ms-transition: all .25s;
					-o-transition: all .25s;
					transition: all .25s;
					&:hover {
						text-decoration: none; } } } } }
	.contacts {
		text-align: right;
		padding-top: 14px;
		p {
			font-family: "Open Sans";
			color: #202d33;
			font-size: 12px;
			font-weight: 400; }
		.phone {
			color: #67087b;
			font-size: 25px;
			font-weight: 400;
			display: block;
			margin-bottom: -7px;
			strong {
				color: #67087b;
				font-size: 25px;
				font-weight: 700; } }
		.mail {
			color: #67087b;
			font-size: 18px;
			font-weight: 700;
			display: block;
			letter-spacing: 0.6px; } } }

.slider {
	.slide-contant {
		position: relative;
		.text-wrap {
			position: absolute;
			top: 123px;
			right: 42px;
			max-width: 425px;
			text-align: right;
			h2 {
				color: white;
				font-family: "Open Sans";
				font-weight: 700;
				font-size: 60px;
				line-height: 49px;
				letter-spacing: 2px;
				position: relative;
				z-index: 1;
				max-width: 422px;
				text-align: right;
				&:before {
					content: '';
					position: absolute;
					width: 97.5%;
					height: 33px;
					background-color: dimgrey;
					z-index: -1;
					right: -3px;
					top: 23px; }
				&:after {
					content: '';
					position: absolute;
					width: 56%;
					height: 33px;
					background-color: dimgrey;
					z-index: -1;
					right: -3px;
					bottom: 43px; }
				span {
					color: white;
					font-family: "Open Sans";
					font-weight: 700;
					font-size: 40px;
					line-height: 45px;
					display: block;
					margin-top: 5px;
					letter-spacing: 1.5px;
					position: relative;
					max-width: 422px;
					text-align: right;
					&:after {
						content: '';
						position: absolute;
						width: 100%;
						height: 33px;
						background-color: dimgrey;
						z-index: -1;
						right: -3px;
						top: 23px; } } }
			p {
				color: white;
				font-family: "Myriad Pro";
				font-size: 16px;
				font-weight: 400;
				padding-top: 20px;
				letter-spacing: 0.6px; }
			.btn {
				width: 130px;
				height: 33px;
				background-color: #66087b;
				border-radius: 2px;
				display: inline-block;
				border-right: 2px solid #fff;
				border-top: 2px solid #fff;
				margin-top: 17px;
				text-align: center;
				color: white;
				font-family: "Open Sans";
				font-size: 15px;
				font-weight: 400;
				display: inline-flex;
				align-items: center; /* cross axis */;
				line-height: 1; /* reset */;
				justify-content: center;
				-webkit-transition: all .25s;
				-moz-transition: all .25s;
				-ms-transition: all .25s;
				-o-transition: all .25s;
				transition: all .25s;
				&:hover {
					background-color: #fff;
					color: #66087b; } } } }
	.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
		text-align: center;
		width: 100%;
		display: inline-block;
		height: 17px;
		padding-top: 0;
		bottom: 15px; }
	.bx-wrapper .bx-pager .bx-pager-item, .bx-wrapper .bx-controls-auto .bx-controls-auto-item {
		background-color: rgba(255,255,255,0.8); }
	.bx-wrapper .bx-pager .bx-pager-item {
		&:first-child {
			padding-left: 3px; }
		&:last-child {
			padding-right: 3px; } }

	.bx-wrapper .bx-pager.bx-default-pager a {
		width: 9px;
		height: 9px;
		background-color: dimgrey;
		margin: 4px 4px 4px 4px; }

	.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
		background-color: #66087b; } }

.features {
	padding-top: 8px;
	padding-bottom: 30px;
	min-height: 86px;
	.text {
		color: #202d33;
		font-family: "Open Sans";
		font-size: 14px;
		font-weight: 400;
		text-transform: uppercase;
		text-align: center;
		max-width: 192px;
		margin: 0 auto;
		line-height: 1.2; } }

.about {
	background: #fefefe; /* Old browsers */;
	background: -moz-linear-gradient(left, #fefefe 0%, #e6e6e6 100%); /* FF3.6-15 */;
	background: -webkit-linear-gradient(left, #fefefe 0%,#e6e6e6 100%); /* Chrome10-25,Safari5.1-6 */;
	background: linear-gradient(to right, #fefefe 0%,#e6e6e6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e6e6e6',GradientType=1 ); /* IE6-9 */;
	min-height: 639px;
	padding-bottom: 30px;
	h2 {
		text-align: center;
		color: #7cc141;
		font-family: "Open Sans";
		font-size: 40px;
		font-weight: 400;
		line-height: 43px;
		text-transform: uppercase;
		padding-top: 26px; }
	.text-descr {
		color: #66087b;
		font-family: "Open Sans";
		font-size: 18px;
		font-weight: 400;
		line-height: 30px;
		text-align: center;
		padding-top: 5px; }
	.text-about {
		color: #202d33;
		font-family: "Open Sans";
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
		padding-top: 21px;
		.p-with-space {
			padding-top: 26px; }
		p {
			text-indent: 1.9em;
			text-align: justify;
			padding-bottom: 5px; }
		strong {
			font-weight: 700; } }
	ul {
		padding-bottom: 30px;
		li {
			text-indent: 1.9em;
			line-height: 24px; } } }

.catalog {
	padding-bottom: 27px;
	h2 {
		text-align: center;
		color: #7cc141;
		font-family: "Open Sans";
		font-size: 40px;
		font-weight: 400;
		line-height: 43px;
		text-transform: uppercase;
		padding-top: 35px; }
	.text-descr {
		color: #66087b;
		font-family: "Open Sans";
		font-size: 18px;
		font-weight: 400;
		line-height: 30px;
		text-align: center;
		padding-top: 3px;
		padding-bottom: 33px; }
	.item-wrap {
		text-align: center;
		margin-bottom: 38px; }
	.img-wrap {
		position: relative;
		height: 170px;
		img {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			display: block;
			border-bottom: 1px solid rgba(32,45,51,0.5); } }
	h3 {
		color: #7fc043;
		font-family: "Open Sans";
		font-size: 35px;
		font-weight: 700;
		padding-top: 5px; }
	ul {
		text-align: left;
		padding-top: 7px;
		li {
			color: #202d33;
			font-family: "Open Sans";
			font-size: 15px;
			font-weight: 400;
			line-height: 1.2; } }
	.more-info {
		color: #202d33;
		font-family: "Open Sans";
		font-size: 15px;
		font-weight: 400;
		text-decoration: underline;
		text-align: center;
		margin-top: 13px;
		display: block;
		-webkit-transition: all .25s;
		-moz-transition: all .25s;
		-ms-transition: all .25s;
		-o-transition: all .25s;
		transition: all .25s;
		&:hover {
			text-decoration: none; } }
	.not-available {
		color: #202d33;
		font-family: "Open Sans";
		font-size: 25px;
		font-weight: 400;
		padding-top: 13px;
		min-height: 52px; }
	.price {
		color: #202d33;
		font-family: "Open Sans";
		font-size: 35px;
		line-height: 1.2;
		padding-top: 9px;
		min-height: 52px;
		span {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 31px;
				width: 12px;
				height: 2px;
				background-color: #202d33; } } }
	.btn-buy {
		width: 127px;
		height: 32px;
		background-color: #66087b;
		border: 1px solid #66087b;
		border-radius: 2px;
		display: inline-block;
		text-align: center;
		color: white;
		font-family: "Open Sans";
		font-size: 15px;
		font-weight: 600;
		text-transform: uppercase;
		margin-top: 15px;
		font-weight: 400;
		display: inline-flex;
		align-items: center; /* cross axis */;
		line-height: 1; /* reset */;
		justify-content: center;
		-webkit-transition: all .25s;
		-moz-transition: all .25s;
		-ms-transition: all .25s;
		-o-transition: all .25s;
		transition: all .25s;
		&:hover {
			background-color: #fff;
			color: #66087b; } } }

footer {
	background: #e6e6e6; /* Old browsers */;
	background: -moz-linear-gradient(left, #e6e6e6 0%, #ffffff 100%); /* FF3.6-15 */;
	background: -webkit-linear-gradient(left, #e6e6e6 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */;
	background: linear-gradient(to right, #e6e6e6 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e6e6', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */;
	.container {
		position: relative;
		padding-bottom: 42px;
		z-index: 1;
		&:after {
			content: '';
			position: absolute;
			right: -24px;
			bottom: 0;
			background-image: url(../img/girl.png);
			width: 290px;
			height: 331px;
			z-index: -1; } }
	nav {
		padding-top: 55px;
		ul {
			li {
				margin-bottom: 8px;
				a {
					color: #202d33;
					font-family: "Open Sans";
					font-size: 12px;
					font-weight: 600;
					text-decoration: underline;
					-webkit-transition: all .25s;
					-moz-transition: all .25s;
					-ms-transition: all .25s;
					-o-transition: all .25s;
					transition: all .25s;
					&:hover {
						text-decoration: none; } } } } }
	.logo-wrap {
		padding-top: 39px;
		.logo {
			display: block;
			margin-top: 19px; }
		p {
			color: #67087b;
			font-family: "Open Sans";
			font-size: 11px;
			font-weight: 600;
			line-height: 13px;
			text-transform: uppercase;
			text-align: center;
			max-width: 181px;
			margin-left: 14px;
			padding-top: 6px; } }
	.contacts-wrap {
		margin-left: 74px;
		padding-top: 52px;
		display: inline-block;
		vertical-align: top;
		p {
			font-family: "Open Sans";
			font-size: 14px;
			line-height: 25px;
			color: #202d33;
			font-weight: 400;
			letter-spacing: 0.5px;
			span {
				color: #67087b;
				font-weight: 700;
				display: block;
				letter-spacing: 0.5px; }
			strong, a {
				color: #202d33;
				font-weight: 700; } } }
	.btn-callback {
		display: block;
		margin-top: 10px;
		width: 321px;
		height: 44px;
		background-color: #7fc244;
		border-radius: 1px;
		text-align: center;
		color: white;
		font-family: "Open Sans";
		font-size: 12px;
		font-weight: 600;
		line-height: 25px;
		display: inline-flex;
		align-items: center; /* cross axis */;
		line-height: 1; /* reset */;
		justify-content: center;
		-webkit-transition: all .25s;
		-moz-transition: all .25s;
		-ms-transition: all .25s;
		-o-transition: all .25s;
		transition: all .25s;
		border: 1px solid #7fc244;
		&:hover {
			background-color: #fff;
			color: #7fc244; } }
	.copy-wrap {
		margin-left: 111px;
		padding-top: 180px;
		display: inline-block;
		vertical-align: top;
		.copy {
			color: #22313f;
			font-family: "Open Sans";
			font-size: 12px;
			font-weight: 400;
			line-height: 23px;
			padding-top: 5px; } } }


.popup1 {
	max-width: 759px;
	background: #ebdfee; /* Old browsers */;
	background: -moz-linear-gradient(left, #ebdfee 0%, #fbf9fc 100%); /* FF3.6-15 */;
	background: -webkit-linear-gradient(left, #ebdfee 0%,#fbf9fc 100%); /* Chrome10-25,Safari5.1-6 */;
	background: linear-gradient(to right, #ebdfee 0%,#fbf9fc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebdfee', endColorstr='#fbf9fc',GradientType=1 ); /* IE6-9 */;
	box-shadow: 1px 1.7px 10px rgba(0, 0, 0, 0.75);
	padding-bottom: 30px;
	margin: 0 auto;
	padding: 5px;
	position: relative;
	.popup-wrap {
		border: 1px solid rgba(168,168,168,0.5);
		overflow: hidden;
		padding-bottom: 35px; }
	h2 {
		color: #7fc043;
		font-family: "Open Sans";
		font-size: 35px;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
		padding-top: 22px; }
	.text-descr {
		color: #202d33;
		font-family: "Open Sans";
		font-size: 13px;
		font-weight: 400;
		text-align: center;
		padding-top: 20px;
		line-height: 1.2; }
	.form-wrap {
		max-width: 700px;
		margin: 0 auto;
		padding-top: 32px;
		p {
			color: #202d33;
			font-family: "Open Sans";
			font-size: 12px;
			font-weight: 600;
			line-height: 16px;
			padding-bottom: 5px;
			letter-spacing: 1.2px; }
		input {
			display: block;
			height: 46px;
			background-color: white;
			border: 1px solid #66087b;
			border-radius: 1px;
			width: 100%;
			margin-bottom: 8px;
			padding-left: 10px; }
		textarea {
			display: block;
			width: 100%;
			height: 196px;
			background-color: white;
			border: 1px solid #66087b;
			border-radius: 1px;
			padding-left: 10px;
			padding-top: 10px; }
		.btn {
			cursor: pointer;
			height: 44px;
			background-color: #7fc244;
			border-radius: 1px;
			width: 100%;
			border: 1px solid #7fc244;
			text-align: center;
			color: white;
			font-family: "Open Sans";
			font-size: 12px;
			font-weight: 600;
			line-height: 25px;
			margin-top: 17px;
			display: block;
			-webkit-transition: all .25s;
			-moz-transition: all .25s;
			-ms-transition: all .25s;
			-o-transition: all .25s;
			transition: all .25s;
			border: 1px solid #7fc244;
			display: inline-flex;
			align-items: center; /* cross axis */;
			line-height: 1; /* reset */;
			justify-content: center;
			letter-spacing: 1px;
			&:hover {
				background-color: #fff;
				color: #7fc244; } } }

	.mfp-close {
		background-image: url(../img/close.png);
		width: 37px;
		height: 37px;
		position: absolute;
		right: 16px;
		top: 17px;
		cursor: pointer; } }

.popup2 {
	.form-wrap {
		textarea {
			height: 121px; } } }

.popup3 {
	background-color: #fff;
	max-width: 1171px;
	margin: 0 auto;
	overflow: hidden;
	padding: 5px;
	position: relative;
	.popup-wrap {
		border: 1px solid rgba(168,168,168,0.5);
		overflow: hidden;
		padding-bottom: 35px; }
	.mfp-close {
		background-image: url(../img/close.png);
		width: 37px;
		height: 37px;
		position: absolute;
		right: 16px;
		top: 17px;
		cursor: pointer; }

	h2 {
		color: #7fc043;
		font-family: "Open Sans";
		font-size: 35px;
		font-weight: 700;
		padding-top: 24px;
		padding-left: 15px;
		padding-right: 15px;
		text-align: center;
		max-width: 850px;
		margin: 0 auto;
		line-height: 1.2; }
	.img-wrap {
		padding-top: 10px;
		text-align: center;
		width: 24%;
		img {
			display: block;
			margin: 0 auto; } }
	.not-available {
		color: #202d33;
		font-family: "Open Sans";
		font-size: 25px;
		font-weight: 400;
		text-align: center; }
	.btn-buy {
		display: block;
		width: 129px;
		height: 34px;
		background-color: #66087b;
		border-radius: 2px;
		color: white;
		font-family: "Open Sans";
		font-size: 15px;
		font-weight: 600;
		text-transform: uppercase;
		text-align: center;
		border: 1px solid #66087b;
		display: inline-flex;
		align-items: center; /* cross axis */;
		line-height: 1; /* reset */;
		justify-content: center;
		-webkit-transition: all .25s;
		-moz-transition: all .25s;
		-ms-transition: all .25s;
		-o-transition: all .25s;
		transition: all .25s;
		margin: 0 auto;
		margin-top: 24px;
		&:hover {
			background-color: #fff;
			color: #66087b; } }
	.text-descr {
		padding-top:  27px;
		padding-left: 9px;
		width: 76%;
		p {
			color: #202d33;
			font-family: "Open Sans";
			font-size: 13px;
			font-weight: 400;
			line-height: 1.2;
			max-width: 800px; } }
	.accord-content {
		display: none;
		color: #202d33;
		font-family: "Open Sans";
		font-size: 13px;
		line-height: 1.2;
		font-weight: 400; }
	.accordion {
		padding-top: 0px; }
	.accord-header {
		font-weight: 700;
		text-decoration: underline;
		color: #202d33;
		font-family: "Open Sans";
		font-size: 13px;
		line-height: 1.2;
		padding-top: 17px;
		cursor: pointer; } }

